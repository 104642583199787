import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import jwtUtil from '@/utils/jwtUtil'
import vueConfig from '@/../vue.config'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/routes/Home')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/routes/Login'),
        props: true
    },
    {
        path: '/categories',
        name: 'Categories',
        component: () => import('@/routes/Category')
    },
    {
        path: '/categories/:mode',
        name: 'Category Create Form',
        component: () => import('@/forms/CategoryForm')
    },
    {
        path: '/categories/:categoryId/:mode',
        name: 'Category Edit Form',
        component: () => import('@/forms/CategoryForm')
    },
    {
        path: '/chargeUnits',
        name: 'ChargeUnits',
        component: () => import('@/routes/ChargeUnit')
    },
    {
        path: '/chargeUnits/:mode',
        name: 'ChargeUnit Create Form',
        component: () => import('@/forms/ChargeUnitForm')
    },
    {
        path: '/chargeUnits/:chargeUnitId/:mode',
        name: 'ChargeUnit Edit Form',
        component: () => import('@/forms/ChargeUnitForm')
    },
    {
        path: '/expenses',
        name: 'Expenses',
        component: () => import('@/routes/Expense')
    },
    {
        path: '/expenses/:mode',
        name: 'Expense Create Form',
        component: () => import('@/forms/ExpenseForm')
    },
    {
        path: '/expenses/:expenseId/:mode',
        name: 'Expense Edit Form',
        component: () => import('@/forms/ExpenseForm')
    },
    {
        path: '/income',
        name: 'Income',
        component: () => import('@/routes/Income')
    },
    {
        path: '/income/:mode',
        name: 'Income Create Form',
        component: () => import('@/forms/IncomeForm')
    },
    {
        path: '/income/:incomeId/:mode',
        name: 'Income Edit Form',
        component: () => import('@/forms/IncomeForm')
    },
    {
        path: '/checks',
        name: 'Checks',
        component: () => import('@/routes/Checks')
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('@/routes/Calendar')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: vueConfig.publicPath,
    routes
})

router.beforeEach((to, from, next) => {
    const token = store.getters.getToken
    if ((!token || (token && jwtUtil.isJwtExpired(token))) && to.name !== 'Login') {
        store.dispatch('logout').then(() => next({ path: '/login' }))
    } else if (token && !jwtUtil.isJwtExpired(token) && to.name === 'Login') {
        next({ path: '/' })
    } else if (!token && to.name === 'Login') {
        next()
    } else {
        if ((from.path.includes('income') && !to.path.includes('income')) || (from.path.includes('expense') && !to.path.includes('expense'))) {
            store.dispatch('setFilters', null)
            store.dispatch('setQuery', null)
        }
        next()
    }
})

export default router
