import api from '@/utils/api'

export default {
    login(data) {
        return api.post(`users/login`, data)
    },
    refreshToken(data) {
        return api.post(`users/refresh-token`, data)
    }
}
