export default {
    state: {
        token: sessionStorage.getItem('logistics-api-token') === 'null' ? null : sessionStorage.getItem('logistics-api-token'),
        drawer: sessionStorage.getItem('drawer') === 'null' ? null : sessionStorage.getItem('drawer') === 'true',
        refreshToken: sessionStorage.getItem('logistics-refreshToken'),
        refreshTokenExpiration: sessionStorage.getItem('logistics-refreshTokenExpiration'),
        filters: null,
        query: null,
        tempExpense: null,
        tempIncome: null
    },
    mutations: {
        setToken(state, value) {
            state.token = value
        },
        setDrawer(state, value) {
            state.drawer = value
        },
        setRefreshToken(state, value) {
            state.refreshToken = value
        },
        setRefreshTokenExpiration(state, value) {
            state.refreshTokenExpiration = value
        },
        setFilters(state, value) {
            state.filters = value
        },
        setQuery(state, value) {
            state.query = value
        },
        setTempExpense(state, value) {
            state.tempExpense = value
        },
        setTempIncome(state, value) {
            state.tempIncome = value
        }
    },
    actions: {
        logout({ dispatch }) {
            dispatch('setToken', null)
            dispatch('setFilters', null)
            dispatch('setQuery', null)
            dispatch('setDrawer', null)
            dispatch('setRefreshToken', null)
            dispatch('setRefreshTokenExpiration', null)
            dispatch('setTempExpense', null)
        },
        login({ dispatch }, [resp]) {
            if (resp.data.token) {
                dispatch('setToken', resp.data.token)
                dispatch('setDrawer', true)
                dispatch('setRefreshToken', resp.data.refreshToken)
                dispatch('setRefreshTokenExpiration', resp.data.refreshTokenExpiration)
            }
        },
        setFilters({ commit }, filters) {
            commit('setFilters', filters)
        },
        setQuery({ commit }, query) {
            commit('setQuery', query)
        },
        setToken({ commit }, token) {
            sessionStorage.setItem('logistics-api-token', token)
            commit('setToken', token)
        },
        setDrawer({ commit }, drawer) {
            sessionStorage.setItem('drawer', drawer)
            commit('setDrawer', drawer)
        },
        setRefreshToken({ commit }, refreshToken) {
            if (refreshToken) {
                sessionStorage.setItem('logistics-refreshToken', refreshToken)
                commit('setRefreshToken', refreshToken)
            } else {
                sessionStorage.removeItem('refreshToken')
                commit('setRefreshToken', null)
            }
        },
        setRefreshTokenExpiration({ commit }, refreshTokenExpiration) {
            if (refreshTokenExpiration) {
                sessionStorage.setItem('logistics-refreshTokenExpiration', refreshTokenExpiration)
                commit('setRefreshTokenExpiration', refreshTokenExpiration)
            } else {
                sessionStorage.removeItem('logistics-refreshTokenExpiration')
                commit('setRefreshTokenExpiration', null)
            }
        },
        setTempExpense({ commit }, tempExpense) {
            sessionStorage.setItem('logistics-temp-entity', tempExpense)
            commit('setTempExpense', tempExpense)
        },
        setTempIncome({ commit }, tempIncome) {
            sessionStorage.setItem('logistics-temp-entity', tempIncome)
            commit('setTempIncome', tempIncome)
        }
    },
    getters: {
        getToken: state => state.token,
        getFilters: state => state.filters,
        getQuery: state => state.query,
        isLoggedIn(state) {
            // is string null because of local storage
            return state.token !== null
        },
        getDrawer: state => state.drawer,
        getRefreshToken: state => state.refreshToken,
        getRefreshTokenExpiration: state => state.refreshTokenExpiration,
        getTempExpense: state => state.tempExpense,
        getTempIncome: state => state.tempIncome
    }
}
