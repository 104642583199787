<template>
    <v-container v-click-outside="{ handler: onClickOutside, include }" fluid class="mx-0 px-0">
        <v-navigation-drawer
            v-model="drawer"
            fixed
            class="drawer pt-12"
            :width="drawer? '180px' : '60px'"
        >
            <v-list
                nav
                dense
            >
                <div v-for="(menuItem, index) in menu" :key="index" @click="setDrawer">
                    <v-list-item :to="menuItem.to" @click="emptyFilters" link>
                        <v-list-item-icon v-if="menuItem.icon" class="mr-3">
                            <v-icon :color="menuItem.color ? menuItem.color : 'grey'">{{ menuItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-if="menuItem.title">{{ menuItem.title }}</v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
    export default {
        name: 'Sidebar',
        data() {
            return {
                menu: [
                    {
                        title: 'Μονάδες Χρέωσης',
                        to: '/chargeUnits',
                        icon: 'mdi-currency-eur'
                    },
                    {
                        title: 'Κατηγορίες',
                        to: '/categories',
                        icon: 'mdi-shape-outline'
                    },
                    {
                        title: 'Έξοδα',
                        to: '/expenses',
                        icon: 'mdi-minus-circle-multiple',
                        color: 'red'
                    },
                    {
                        title: 'Έσοδα',
                        to: '/income',
                        icon: 'mdi-plus-circle-multiple',
                        color: 'green'
                    },
                    {
                        title: 'Επιταγές',
                        to: '/checks',
                        icon: 'mdi-cash-sync',
                        color: 'grey'
                    },
                    {
                        title: 'Ημερολόγιο',
                        to: '/calendar',
                        icon: 'mdi-calendar',
                        color: 'grey'
                    }
                ]
            }
        },
        computed: {
            drawer: {
                get() {
                    return this.$store.getters.getDrawer
                },
                set(value) {
                    return value
                }
            }
        },
        methods: {
            emptyFilters() {
                this.$store.dispatch('setFilters', null)
            },
            onClickOutside() {
                this.setDrawer()
            },
            setDrawer() {
                if (this.drawer) {
                    this.$store.dispatch('setDrawer', !this.drawer)
                }
            },
            include() {
                return [document.querySelector('.included')]
            }
        }
    }
</script>

<style scoped>

a {
    color: #0060B6;
    text-decoration: none;
}

a:hover {
    color:#00A0C6;
    text-decoration:none;
    cursor:pointer;
}
.sub-text{
    font-size: 0.8rem;
    color: black;
}
.container {
    align-self: center;
    height: 100%;
}
</style>
